<template>
  <div class="">
    <van-nav-bar title="Amazfit Care+" left-arrow  @click-left="$router.go(-1)"/>
    <div class="banner">
      <img :src="imgs.banner" alt="">
    </div>
    <div class="items">
      <a href="/news" class="item">
        <img :src="imgs.ico1" alt="">
        <div class="intro">
          <h2>用户心声</h2>
          <p>在这里你可以发表自己的声音，或者对我们说出你的想法</p>
        </div>
      </a>
      <a href="/news" class="item">
        <img :src="imgs.ico2" alt="">
        <div class="intro">
          <h2>用户心声</h2>
          <p>在这里你可以发表自己的声音，或者对我们说出你的想法</p>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
import { CDN_URL } from '../../config'
export default {
  name: 'care',
  data () {
    return {
      imgs: {
        banner: CDN_URL + '/images/service/care/care-1.png',
        ico1: CDN_URL + '/images/service/care/care-2.png',
        ico2: CDN_URL + '/images/service/care/care-3.png'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.banner {
  img {
    width: 100%;
  }
}
.items {
  padding: 7vw 0;
  width: 86vw;
  margin: 0 auto;
  .item {
    position: relative;
    width: 100%;
    margin-bottom: 5vw;
    display: block;
    .intro {
      position: absolute;
      top: 4.4vw;
      left: 6.8vw;
      width: 50vw;
      color: #fff;
      h2 {
        font-size: 4vw;
        margin: 0;
      }
      p {
        font-size: 2.9vw;
        margin-top: 1vw;
        line-height: 1.6;
      }
    }
  }
}
</style>
